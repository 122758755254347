<template>
  <div class="page flex-col" v-loading="loading">
    <!-- <div style="min-width: 300px; height: 600px"></div> -->
    <!-- <el-carousel trigger="click" height="600px" width="300px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            fit="cover"
            :src="item"
            :preview-src-list="bannerList"
            :initial-index="index"
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>
      </el-carousel> -->

    <el-form
      v-if="form"
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <div class="flex">
        <div style="min-width: 400px">
          <el-form-item label="头像：">
            <el-input v-model="form.avatar.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="姓名：">
            <el-input v-model="form.nickname.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="性别：">
            <el-input v-model="form.gender.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="出生年月：">
            <el-input v-model="form.birthday.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="求职类型：">
            <el-input v-model="form.work_type.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="求职状态：">
            <el-input
              v-model="form.work_status.tips_text"
              placeholder="请输入"
              style="margin-bottom: 12px"
            >
            </el-input>
            <Tag v-model="form.work_status.text_options" />
          </el-form-item>
        </div>
        <div style="min-width: 400px">
          <el-form-item label="意向城市：">
            <el-input
              v-model="form.intention_city.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="求职期望：">
            <el-input
              v-model="form.intention_career.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="期望月薪：">
            <el-input
              v-model="form.intention_wages.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="毕业院校：">
            <el-input
              v-model="form.school_graduate.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="就读专业：">
            <el-input
              v-model="form.school_profession.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="最高学历：">
            <el-input
              v-model="form.school_history.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="就读时间：">
            <el-input v-model="form.school_time.tips_text" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="教育经历：">
            <el-input
              v-model="form.teacher_experience.tips_text"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <h3 class="config-section-title" style="margin-top: 40px">使用协议</h3>
    <el-form
      ref="agreementForm"
      label-position="right"
      label-width="100px"
      class="save-content"
      style="max-width: 600px"
      :model="form.use_agreement"
    >
      <el-form-item label="协议开关：" prop="status">
        <el-switch
          v-model="form.use_agreement.status"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="协议标题："
        prop="title"
        :required="form.use_agreement.status === 1"
        :show-message="false"
      >
        <el-input
          v-model="form.use_agreement.title"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="自定义内容："
        prop="content"
        :required="form.use_agreement.status === 1"
        :show-message="false"
      >
        <el-input
          type="textarea"
          v-model="form.use_agreement.content"
          placeholder="请输入"
          :rows="26"
        ></el-input>
      </el-form-item>
    </el-form>

    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import Tag from '@/modules/employ/components/employ-config/Tag.vue'
// 接口
import {
  getProfileConfig,
  saveProfileConfig,
} from '@/modules/employ/api/employ-config'
import areaData from '@/modules/common/json/address.json'

export default {
  components: { FixedActionBar, Tag },
  data() {
    return {
      areaData,
      form: null,
      // 校验规则
      rules: {
        // design: [{ required: true, message: '请选择布局', trigger: 'change' }],
        // images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
      loading: true,
    }
  },

  methods: {
    validateForm(formRefName, callback) {
      this.$refs[formRefName].validate((valid) => {
        if (valid) {
          callback()
        }
      })
    },

    // 保存配置
    saveConfig() {
      this.validateForm('agreementForm', () => {
        let postData = {
          config: {
            ...this.form,
          },
        }

        this.loading = true
        saveProfileConfig(postData)
          .then((res) => {
            this.$message.success(res.msg)
            this.getDetail(0)
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      })
    },
    // 获取详情
    getDetail() {
      this.loading = true
      getProfileConfig()
        .then((res) => {
          this.form = res.data

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getDetail()
  },
}
</script>

<style lang="scss" scoped>
.page {
  min-width: 960px;
  background-color: #ffffff;
  margin: -20px;
  padding: 20px;
  min-height: calc(100vh - 60px - 131px - 20px * 2);
  border-radius: 5px;
}
.form-tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 1.5;
  margin-top: 8px;
  span + span {
    margin-left: 4px;
  }
}
</style>
